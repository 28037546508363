// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://d2jmno6z2k.execute-api.ap-southeast-2.amazonaws.com/dev',
  headers: {
    'classify': {
      'Content-Type': 'image/jpeg',
      'x-api-key': 'EMCtEbg6Fw2mmJYj2V4U3aq70OoEXBWA1U28lt6t',
    },
    'object_detection': {
      'Content-Type': 'image/jpeg',
      'x-api-key': 'EMCtEbg6Fw2mmJYj2V4U3aq70OoEXBWA1U28lt6t'
    },
    'OCR': {
      'Content-Type': 'image/jpeg',
      'x-api-key': 'EMCtEbg6Fw2mmJYj2V4U3aq70OoEXBWA1U28lt6t'
    },
    'landmark_detection': {
      'Content-Type': 'image/jpeg',
      'x-api-key': 'EMCtEbg6Fw2mmJYj2V4U3aq70OoEXBWA1U28lt6t'
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
