import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-demos',
  templateUrl: './demos.component.html',
  styleUrls: ['./demos.component.scss']
})
export class DemosComponent implements OnInit {

  file: File;
  provider: string;
  result$: Observable<any>;
  image: any;
  submitted = false;
  custom_classifciation = null;
  service: string;
  isHovering = false;

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  reset() {
    this.image = null;
    this.file = null;
    this.submitted = false;
    this.result$ = null;
    this.provider = null;
  }

  toggleHover(event) {
    this.isHovering = event;
  }

  loadImage(files) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.image = reader.result;
    };
    this.file = files[0];
  }

  async submit(type) {
    this.submitted = true;
    const headers = environment.headers[type];
    headers['x-provider'] = this.provider;
    if (type === 'classify' && this.provider === 'customModel') {
      headers['x-model-name'] = 'multi_label_train_syringe_graffiti';
    }
    const options = {
      headers: new HttpHeaders(headers),
    };
    this.result$ = this.http.post(`${environment.baseURL}/${type}`, this.file, options);
  }

}
